<template>
    <div id="filpLogo"></div>
</template>
<script>
import * as THREE from 'three'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader'
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
export default {
  data () {
    return {
      clearRenderer: null,
    }
  },
  methods: {
    createThree () {
      // 场景
      const scene = new THREE.Scene()
      // 相机
      let w = window.innerWidth;
      let h = window.innerHeight/5;
      const camera = new THREE.PerspectiveCamera(90,w/h, 0.1, 1000)
      camera.position.set(0, 0, 500)
      // 添加相机到场景中
      scene.add(camera)
      // 文字
      const loader = new FontLoader();
      loader.load( 'fonts/helvetiker_bold.typeface.json', 
        function ( font ) {
          const geometry = new TextGeometry( 'FLIP', {
              font: font,
              size: 260,
              height: 80,
              curveSegments: 12,
              bevelEnabled: false,
              bevelSize: 8,
              bevelSegments: 5,
          } );
          var meshMaterial = new THREE.MeshMatcapMaterial({color: 0xffffff}); // 材质
          var mesh = new THREE.Mesh(geometry, meshMaterial);
          mesh.position.set(-300, -200, 0);
          scene.add(mesh);
        },
        //加载进度
        function ( xhr ) {
          console.log( (xhr.loaded / xhr.total * 100) + '% loaded' );
        },
        //出现错误
        function (err) {
          console.log(err);
        }
      );
      // 渲染器
      const renderer = new THREE.WebGLRenderer({
        antialias: true,
        alpha: true
      });
      // 渲染器透明
      renderer.setClearAlpha(0);
      // 渲染器尺寸
      renderer.setSize(w, h)
      document.getElementById("filpLogo").appendChild(renderer.domElement)
      // 控制器
      const controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true;  // 阻尼
      controls.enableZoom = false;  // 锯齿

      function render() { 
        controls.update()
        renderer.render(scene, camera)
        requestAnimationFrame(render)
      }
      render()

      // window.addEventListener('resize', ()=>{
      //   // 1. 更新摄像头的宽高比
      //   camera.aspect = (window.innerWidth/2) / (window.innerHeight/5); 
      //   // 2. 更新摄像机的投影矩阵
      //   camera.updateProjectionMatrix();

      //   // 3. 更新渲染器
      //   renderer.setSize(window.innerWidth/2, window.innerHeight/5);
      //   // 4. 设置渲染器的像素比
      //   renderer.setPixelRatio(window.devicePixelRatio);
      //   // document.getElementById("filpLogo").style.setProperty()
      // })
      this.clearRenderer = () => {
        console.log('clearRenderer')
        renderer.forceContextLoss();
      }
    }
  },
  mounted() {
    this.createThree()
  },
  unmounted () {
    this.clearRenderer()
  }
}
</script>
<style lang="scss" scoped>
</style>


<template>
  <div id="homeContent" :style="homeBackground">
    <div class="home-bg-mask"></div>
    <div class="home-3d-logo">
      <logoword v-for="(e,i) in logoWords"
                :key="i"
                ref="lw"
                :word="e.word"
                :elName="e.el"
                :coordinate="e.coordinate"
                :position="e.position"
                :imgIndex="imgIndex"></logoword>
    </div>
    <div class="flip-header">
      <!-- <div class="flip-header-btn home-header-tab" :style="{backgroundImage:'url('+headerIcons[0]+')'}" @click="handleLogoImage()"></div> -->
      <div class="flip-header-btn home-header-tab"
           @click="this.$store.commit('chgLang')"
           :style="$store.state.isEn?{backgroundImage:'url('+headerIcons[1]+')'}:{backgroundImage:'url('+headerIcons[3]+')'}"></div>
      <div v-if="!isExpand"
           class="flip-header-btn home-header-tab"
           :style="{backgroundImage:'url('+headerIcons[2]+')'}"
           @touchstart="isExpand=true">
        <!-- @mouseleave="isExpand=false"> -->
      </div>
      <div v-else
           class="home-header-tab">
        <span class="search-contain">
         <form action="" @submit.prevent="onSearch" style="width:100%; height: 100%">
            <input class="search-input"
            :class="$store.state.isEn?'fontStyle6 search-input-en':'fontStyle6CnForSearch search-input-cn'"
                 type="search"
                 :placeholder="$store.state.isEn?searchPlaceHolder:searchPlaceHolderCn"
                 v-model="searchValue"
                 @focus="handleFocus"
                 @blur="handleBlur"
                 @keydown="handleSearch($event)">
          </form>


          <!-- <input class="search-input fontStyle1"
                 type="search"
                 :placeholder="searchPlaceHolder"
                 v-model="searchValue"
                 @focus="handleFocus"
                 @blur="handleBlur"
                 @keydown="handleSearch($event)"> -->
        </span>
      </div>

    </div>
    <div class="home-footer fontStyle1">
      <div class="home-footer-item"
           @click="clickStudio()">
        <span id="studioEnter">FLIP Studio</span>
      </div>
      <div class="home-footer-item"
           @click="clickPop()">
        <span id="popEnter">FLIP POP</span>
      </div>
    </div>
  </div>
</template>

<script>
// import StericalComp from 'components/common/StericalComp'
// import Logoword from '@/components/common/logoword/logoword.vue';
import gsap from 'gsap';

export default {
  // components: { StericalCompLogoword },
  data () {
    return {
      searchPlaceHolder: 'Search',
      searchPlaceHolderCn: '搜索',
      headerIcons: [
        require('assets/image/homeSwitch@2x.png'),
        require('assets/image/o_homeLanguage@2x.png'),
        require('assets/image/o_homeSearch@2x.png'),
        require('assets/image/o_homeLanguageEn@2x.png'),
      ],
      arrowIcon: require('assets/image/arrow@2x.png'),
      isExpand: false, // 展开搜索框
      isFocus: false,
      searchValue: '',
      logoWords: [
        {
          word: "F",
          el: "filpLogoWordF",
          coordinate: { // 模型
            x: -137,
            y: -250,
            z: -150
          },
          position: { //相机
            x: -300,
            y: 700,
            z: -300
          }
        },
        {
          word: "L",
          el: "filpLogoWordL",
          coordinate: {
            x: -170,
            y: -250,
            z: -50
          },
          position: {
            x: 350,
            y: 300,
            z: 600
          }
        },
        {
          word: "I",
          el: "filpLogoWordI",
          coordinate: {
            x: -5,
            y: -250,
            z: -50
          },
          position: {
            x: 150,
            y: 400,
            z: 600
          }
        },
        {
          word: "P",
          el: "filpLogoWordP",
          coordinate: {
            x: -200,
            y: -250,
            z: -50
          },
          position: {
            x: 250,
            y: -300,
            z: 600
          }
        },
      ],
      isCover: false,
      homeBackground: {},
      imgIndex: -1,
      imgSrc: 'https://miniapp-1253923639.cos.ap-nanjing.myqcloud.com/flip/',
    }
  },
  methods: {
    handleLogoImage () {
      if (!this.isCover) {
        (this.$refs.lw).forEach(e => { e.addMaterial() })
        this.isCover = !this.isCover
      } else {
        (this.$refs.lw).forEach(e => { e.removeMaterial() })
        this.isCover = !this.isCover
      }
    },
    handleFocus () {
      this.isFocus = true;
    },
    handleBlur () {
      this.isExpand = false;
      this.isFocus = false;
    },
    handleSearch (e) {
      console.log(e.keyCode)
      if (e.keyCode == 13) {
        console.log('回车搜索', this.searchValue)
        this.$router.push(
          {
            name: 'Search',
            query: {
              value: this.searchValue
            }
          }
        )
      }
    },
    flipWord (elementId = '', direction = 0) { // flag0 - 反； 1 - 正
      if (direction) {
        gsap.to(elementId, { rotateY: ('0deg'), duration: 0.5 })
        gsap.to(elementId, { color: '#313131', duration: 0.1 })
      } else {
        gsap.to(elementId, { rotateY: ('180deg'), duration: 0.5 })
        gsap.to(elementId, { color: '#00eb96', duration: 0.1 })
      }
    },
    resetFlip () {
        gsap.to('#studioEnter', { rotateY: ('0deg'), duration: 0 })
        gsap.to('#studioEnter', { color: '#313131', duration: 0 })
        gsap.to('#popEnter', { rotateY: ('0deg'), duration: 0 })
        gsap.to('#popEnter', { color: '#313131', duration: 0 })
    },
    clickStudio () {
      this.flipWord('#studioEnter', 0)
      this.$router.push(
        {
          name: 'Studio',
        }
      )
    },
    clickPop () {
      this.flipWord('#popEnter', 0)
      this.$router.push(
        {
          name: 'Pop',
        }
      )
    },
    onSearch() {
      return
    },
    getHomeImage () {
      this.imgIndex = Math.floor((Math.random() * 4) + 1);
      this.homeBackground = {
        // backgroundImage: 'url(' + require('assets/image/home_00'+this.imgIndex+'.jpg') + ')',
        backgroundImage: 'url(' + this.imgSrc + 'home_00'+ this.imgIndex + '.jpg)',
      }
    }
  },

  mounted() {
    this.getHomeImage()
    this.resetFlip()
  }

}
</script>

<style>
.home-logo-temp {
  font-size: 300px;
  font-weight: 900;
}
</style>
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import loading from './components/common/loading/loading'
import logo from './components/common/logo/logo'
import logoword from './components/common/logoword/logoword'
// import Vconsole from 'vconsole'
import store from './store'

import './assets/style/global.scss'
import './assets/style/flip.scss'
import 'assets/style/font.css'
// const vConsole = new Vconsole()

createApp(App).use(router).use(loading).use(logo).use(logoword).use(store).mount('#app')
